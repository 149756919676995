.sheetcontainer {
    padding-bottom: 150px;
}

.synccontainer {
    position: absolute;
    width: 200px;
    top: 100px;
    right: 0px;
    text-align: left;
}

.textfieldts {
    width: 70px;
}

.textfieldmeasure {
    width: 70px;
}

.syncpointtextfieldcontainer {
    display: flex;
}

.performancesdropdown {
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
}

.navbar {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarlogostack {
    left: 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarbuttonstack {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.musicistbutton {
    margin-left: -20px;
}

.musicistimage {
    width: 40px;
    height: 40px;
}