.compositionlistcontainer {
    font-family: "Times New Roman", Times, serif;
}


.navbar {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.uploadbutton {
    background-color: '#FF6329';
    border-color: '#FF6329';
}

.navbarbuttonstack {
    position: absolute;
    right: 10px;
}

.musicistimage {
    width: 40px;
    height: 40px;
}

.navbarlogostack {
    left: 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tagline {
    font-family: arial;
    font-family: 'Scope One', serif;
    font-size: 18px;
    color: #FF6329;
}