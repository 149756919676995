.waveformcontainer {
    position:fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    height:75px;
    vertical-align: middle;
    background-color: #fffbfa;
    box-shadow: 0px 0px 20px 0px #eeeeee;
}

.waveform {
    width: 100%;
    height: 75px;
}

.waveformwrapper {
    width: 100%;
    height: 75px;
    position: relative;
}

.playbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left:10px;
    margin-right:10px;
}

.playbutton:hover {
    background: #FFDFD4;
}

.button {
    height: 30px;
    width: 30px;
    margin-left: 10px;
    fill: #FF6329;
}

.timestamp {
    font-size: 10px;
    color: #aaaaaa;
    position: absolute;
    bottom: -3px;
    left: -18px;
    transform: translateX(-50%);    
}